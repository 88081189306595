import React from 'react'
import { DefaultLayout } from '../components/Layouts'

import nxslogo from '../images/nxs-logo.svg'

const IndexPage = () => (
  <DefaultLayout>
    <div className="rounded p-12 text-4xl sm:text-6xl text-white leading-none bg-cover bg-grey-900 hero-title font-bold">
      style
      <br />
      guides
    </div>
    <p className="sm:flex justify-center items-center leading-tight mt-3 text-center text-grey-600">
      Prepared by{' '}
      <a href="https://www.northxsouth.co">
        <img
          src={nxslogo}
          className="w-48 h-auto block mt-2 mx-auto sm:h-6 sm:w-auto sm:inline-block sm:ml-2 sm:mt-0"
          alt="North X South logo"
        />
      </a>
    </p>
  </DefaultLayout>
)

export default IndexPage
